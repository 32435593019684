/* stylelint-disable */
.dropdown {
  color: $gray;
  &:hover .expand-more {
    color: $brand-primary;
  }
  .expand-more {
    color: $gray-darker;
    cursor: pointer;
    user-select: none;
  }

  .active {
    max-height: 200px;
    overflow-y: hidden;
    visibility: visible;
  }

  select {
    color: $gray-darker;
    -moz-appearance: none;
    background: white;
    border: 0 none;
    outline: 0 none;
  }
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: $brand-primary;
  text-decoration: none;
  background: none;
}
