/*==========================================================================*\
  Header desktop
\*==========================================================================*/

/* Base
\*==========================================================================*/

.header-desktop {
  @apply text-white;
  box-shadow: 0px 5px 5px 0 rgba(0, 0, 0, 0);

  .menu-actions--desktop {
    > * {
      &:not(:last-child) {
        @apply mr-5;
      }
    }
  }
}

.header-desktop__nav {
  padding-top: 1.5em;
  padding-bottom: 1.5em;
}

.header-desktop__logo {
  height: 103px;
}

.header-desktop__logo-link {
  width: 140px;
  height: 100%;
}

.header-desktop__logo-icon--icon {
  transition: opacity 0.3s;
}

.header-cart-count {
  font-size: 0.625rem;
}

/* Is scroll
\*==========================================================================*/

.is-scroll {
  .header-desktop {
    @apply text-black bg-sand;
    box-shadow: 0px 5px 5px 0 rgba(0, 0, 0, 0.05);
  }
}

/* Is scroll bottom
\*==========================================================================*/

.is-scroll-bottom {
  .header-desktop__logo-icon--icon {
    opacity: 0;
  }
}

/* Is submenu enter
\*==========================================================================*/

.is-submenu-enter {
  .header-desktop {
    @apply text-black bg-sand;
    box-shadow: 0px 5px 5px 0 rgba(0, 0, 0, 0.05);
  }
}
