/*============================================================================*\
  Header height
\*============================================================================*/

$add-class: true !default;

/* Classes
\*==========================================================================*/

@if $add-class {
  .header-height {
    height: 88px;

    @screen l {
      height: 183px;
    }
  }
}
