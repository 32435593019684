/*============================================================================*\
  Wysiwyg
\*============================================================================*/

$add-class: true !default;

/* Basic
\*==========================================================================*/

@mixin wysiwyg {
  // Title
  h1 {
    @apply type-h1;
  }

  h2 {
    @apply type-h2;
  }

  h3 {
    @apply type-h3;
  }

  h4 {
    @apply type-h4;
  }

  h1,
  h2,
  h3,
  h4 {
    font-weight: 500;
  }

  // Inline tag
  strong,
  b {
    font-weight: 600;
  }

  em {
    font-style: italic;
  }

  a:not(.btn) {
    @apply text-brown;
    font-weight: 400;
    text-decoration: none;
    text-decoration: underline;
    cursor: pointer;
  }

  // Paragraph
  p {
    font: inherit !important;
    background: inherit !important;
  }

  .intro {
    @apply type-intro #{!important};
  }

  // Image and figure
  img {
    max-width: 100%;
    height: auto;
  }

  figure {
    margin-bottom: 2em;
  }

  figcaption {
    @apply text-small;
  }

  // Blockquote
  blockquote {
    @apply type-blockquote;
    padding: 0 2em;
    border-left: 1px solid rgba(#333, 0.2);

    // &:not(:first-child) {
    //   margin-top: 4.166666667em !important;
    // }

    // &:not(:last-child) {
    //   margin-bottom: 4.166666667em !important;
    // }
  }

  // List
  ul {
    list-style-type: none;
  }

  li {
    padding-left: 1.5em;

    &:before {
      @apply bg-brown;
      content: '';
      position: absolute;
      top: 1em;
      left: 0;
      width: 0.5em;
      height: 1px;
    }
  }

  ol {
    li {
      list-style-type: decimal;
    }
  }

  // All tag vertical marge
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  img,
  figure,
  blockquote,
  ul,
  ol,
  pre {
    span {
      font: inherit !important;
      background: inherit !important;
    }

    &:not(:last-child) {
      margin-bottom: 1.5em;
    }
  }
}

@if $add-class {
  .wysiwyg {
    @include wysiwyg;
  }
}

/* Title colors
\*==========================================================================*/

// White
@mixin wysiwyg-title-white {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply text-white;
  }
}

@if $add-class {
  .wysiwyg--title-white {
    @include wysiwyg-title-white;
  }
}

// Black
@mixin wysiwyg-title-black {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply text-black;
  }
}

@if $add-class {
  .wysiwyg--title-black {
    @include wysiwyg-title-black;
  }
}

/* CMS content
\*==========================================================================*/

@mixin wysiwyg-cms-content {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul,
  ol,
  blockquote,
  table,
  pre,
  address {
    max-width: 43.75rem;
    margin-right: auto;
    margin-left: auto;
  }

  img {
    left: 50%;
    width: calc(100vw - 2.5rem);
    max-width: 1216px;
    transform: translateX(-50%);
  }

  .page-builder__block, // OLD
  .page-builder-content-image {
    // NEW
    img {
      left: auto;
      width: auto;
      max-width: 100%;
      transform: none;
    }
  }

  .page-builder__breakline {
    display: none;
  }

  .page-builder-content-image {
    margin-top: 1.5em;
    margin-bottom: 1.5em;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  @screen s {
    .page-builder-content-image {
      margin-top: 3em;
      margin-bottom: 3em;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @screen m {
    .page-builder-content-image {
      margin-top: 6em;
      margin-bottom: 6em;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @screen l {
    .page-builder-content-image {
      margin-top: 10em;
      margin-bottom: 10em;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@if $add-class {
  .wysiwyg--cms-content {
    @include wysiwyg-cms-content;
  }
}
