/*==========================================================================*\
  Menu mobile
\*==========================================================================*/

/* Base
\*==========================================================================*/

.menu-mobile__list--primary {
  .menu-mobile__list {
    transform: translateX(-100%);
    transition: transform 0.3s;
  }
}

.menu-mobile__item {
  &.is-open {
    .menu-mobile__list {
      transform: translateX(0);
    }
  }
}

.menu-mobile__link--primary,
.menu-mobile__link-to-submenu,
.menu-mobile__link--tertiary {
  transition: opacity 0.3s;

  .is-submenu-open & {
    opacity: 0;
  }
}
