/*============================================================================*\
  Maintenance Slider
\*============================================================================*/

$add-class: true !default;

/* Classes
\*==========================================================================*/

@if $add-class {
  .maintenance-slider__slider-content {
    outline: none;

    &:not(.is-flickity-ready) {
      .maintenance-slider__slider-content-item:not(:first-child) {
        z-index: -1;
        position: absolute;
        opacity: 0;
      }
    }
  }

  .maintenance-slider__slider-image {
    outline: none;

    &:not(.is-flickity-ready) {
      .maintenance-slider__slider-image-item:not(:first-child) {
        z-index: -1;
        position: absolute;
        opacity: 0;
      }
    }
  }

  .maintenance-slider__dot {
    @apply bg-black;
    max-width: 41px;
    height: 2px;
    opacity: 0.2;
    transition: background-color 0.2s, opacity 0.2s;

    &.is-active {
      @apply bg-brown;
      opacity: 1;
    }
  }
}
