/*============================================================================*\
  Accordion
\*============================================================================*/

$add-class: true !default;

/* Basic
\*==========================================================================*/

@mixin accordion-btn {
  * {
    pointer-events: none;
  }

  .accordion__btn-icon {
    transition: transform 0.2s;
  }

  &.is-down {
    .accordion__btn-icon {
      transform: rotateX(180deg);
    }
  }
}

@if $add-class {
  .accordion__btn {
    @include accordion-btn;
  }
}
