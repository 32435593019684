/*============================================================================*\
  Post
\*============================================================================*/

$add-class: true !default;

/* Classes
\*==========================================================================*/

@if $add-class {
  .post-miniature__image {
    background-color: #f5ece3;

    &:before {
      content: '';
      display: block;
      padding-bottom: 100%;
    }
  }

  .post-miniature__image-viewport {
    transform: scale3d(1.1, 1.1, 1);
    transition: transform 0.4s;
  }

  .post-miniature__link {
    &:hover,
    &:focus {
      .post-miniature__image-viewport {
        transform: scale3d(1, 1, 1);
      }
    }
  }

  .post-miniature__cursor {
    opacity: 0;
    transition: opacity 0.05s, transform 0.075s;

    .is-enter & {
      opacity: 1;
      transition: opacity 0.05s 0.075s, transform 0.075s;
    }
  }
}
