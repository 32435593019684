/*============================================================================*\
  Field textarea
\*============================================================================*/

$add-class: true !default;

/* Basic
\*==========================================================================*/

@mixin field-textarea {
  @apply type-body font-light;
  display: block;
  width: 100%;
  padding: 0.938em 1.5em;
  background-color: rgba(#111, 0.08);
  border: 1px solid transparent;
  outline: none;

  &:focus {
    border-bottom-color: #{theme('colors.brown.default')};
  }

  &::placeholder {
    color: rgba(#333, 0.5);
  }

  // Form group status
  .field-group.has-error & {
    @apply text-notice-red;
    border-bottom-color: #{theme('colors.notice-red')};
    outline: none;
  }
}

@if $add-class {
  .field-textarea {
    @include field-textarea;
  }
}
