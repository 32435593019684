/*==========================================================================*\
  Header mobile
\*==========================================================================*/

/* Base
\*==========================================================================*/

.header-mobile {
  @apply text-white bg-transparent;
  transition: color 0.3s, background-color 0.3s;

  .is-scroll &,
  .is-menu-open & {
    @apply text-black bg-sand;
  }
}

.header-mobile__shadow-palm {
  display: none;
}

.header-mobile__nav {
  padding-top: 1.75em;
  padding-bottom: 1.75em;
}

.header-mobile__menu-btn-icon--open,
.header-mobile__menu-btn-icon--close {
  transition: opacity 0.3s;
}

.header-mobile__menu-btn-icon--open {
  .is-menu-open & {
    opacity: 0;
  }
}

.header-mobile__menu-btn-icon--close {
  opacity: 0;

  .is-menu-open & {
    opacity: 1;
  }
}

.header-mobile__logo {
  transition: opacity 0.3s;

  .is-menu-open & {
    pointer-events: none;
    opacity: 0;
  }
}

.header-mobile__action-primary,
.header-mobile__action-secondary {
  > * {
    &:not(:last-child) {
      margin-right: 1.25rem;
    }
  }
}

.header-mobile__action-primary {
  transition: opacity 0.3s;

  .is-menu-open & {
    pointer-events: none;
    opacity: 0;
  }
}

.header-mobile__action-secondary {
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s;

  .is-menu-open & {
    pointer-events: auto;
    opacity: 1;
  }
}

.header-mobile__aside {
  transform: translateX(-100%);
  transition: transform 0.3s;

  .is-menu-open & {
    transform: translateX(0);
  }
}

/* Is black
\*==========================================================================*/

.header--text-black {
  .header-desktop,
  .header-mobile {
    @apply text-black;
  }
}
