/*============================================================================*\
  Headline
\*============================================================================*/

$add-class: true !default;

/* Classes
\*==========================================================================*/

@if $add-class {
  .headline__title-text,
  .headline__caption {
    padding-bottom: 0.1em;
  }

  .headline__figure-container {
    &:before {
      content: '';
      z-index: 1;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: linear-gradient(to bottom, rgba(#fffbf5, 0.1) 0%, rgba(#fffbf5, 1) 100%);
    }
  }
}
