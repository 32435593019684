/*============================================================================*\
  Button
\*============================================================================*/

$add-class: true !default;

/* Basic
\*==========================================================================*/

@mixin btn {
  @apply type-body font-medium text-white bg-brown;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.875em 2.069em;
  overflow: hidden;
  text-align: center;
  text-decoration: none;
  user-select: none;
  border: 2px solid transparent;
  outline: none;
  transition: background-color 0.5s;

  * {
    pointer-events: none;
  }

  svg {
    &:first-child {
      margin-right: 1em;
    }

    &:last-child {
      margin-left: 1em;
    }
  }

  &:hover,
  &:focus {
    @apply bg-brown-light;
    outline: none;
  }

  &.btn--disabled,
  &:disabled {
    pointer-events: none;
    opacity: 0.3;
  }
}

@if $add-class {
  .btn {
    @include btn;
  }
}

/* Anime text
\*==========================================================================*/

@mixin btn-anime {
  [data-text] {
    @apply z-default;
    display: inline-block;
    transition: opacity 0.5s, transform 0.5s;
  }

  [data-text-hover] {
    @apply z-default;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    transform: translateY(2.875em);
    transition: opacity 0.5s, transform 0.5s;

    &:before {
      content: attr(data-text-hover);
    }
  }

  &:hover,
  &:focus {
    @apply bg-brown-light;

    [data-text] {
      animation: btn-anime-text 0.5s ease;
    }

    [data-text-hover] {
      animation: btn-anime-text-hover 0.5s ease;
    }
  }

  &.btn--disabled,
  &:disabled {
    &:hover,
    &:focus {
      [data-text] {
        opacity: 1;
        transform: translateY(0);
      }

      [data-text-hover] {
        opacity: 0;
        transform: translateY(1em);
      }
    }
  }
}

@keyframes btn-anime-text {
  0% {
    opacity: 1;
  }

  70% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@keyframes btn-anime-text-hover {
  0% {
    opacity: 0;
    transform: translateY(2em);
  }

  25% {
    opacity: 0;
    transform: translateY(1.5em);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@if $add-class {
  .btn--anime {
    @include btn-anime;
  }
}

/* Stroke
\*==========================================================================*/

@mixin btn-stroke {
  @apply border-brown border-opacity-50 text-black;
  background-color: transparent;
  transition: border-color 0.5s;

  &:hover,
  &:focus {
    @apply border-opacity-100;
    background-color: transparent;
  }

  &.btn--disabled,
  &:disabled {
    pointer-events: none;
    background-color: transparent;

    &:hover,
    &:focus {
      [data-text] {
        transform: translateY(0);
      }

      [data-text-hover] {
        transform: translateY(1em);
      }
    }
  }
}

@if $add-class {
  .btn--stroke {
    @include btn-stroke;
  }
}

/* No appearance
\*==========================================================================*/

@mixin btn-no-appearance {
  margin: 0;
  padding: 0;
  background-color: transparent;
  background-image: none;
  border: 0;
  border-radius: 0;

  &:hover,
  &:focus {
    outline: none;
  }
}

@if $add-class {
  .btn--no-appearance {
    @include btn-no-appearance;
  }
}

/* White
\*==========================================================================*/

// Basic
@mixin btn-white {
  @apply text-brown bg-white;

  &:hover,
  &:focus {
    @apply bg-white;
  }
}

// Stroke
@mixin btn-white-stroke {
  @apply border-white border-opacity-50 text-white;
  background-color: transparent;

  &:hover,
  &:focus {
    @apply border-opacity-100;
    background-color: transparent;
  }
}

@if $add-class {
  .btn--white {
    @include btn-white;

    &.btn--stroke {
      @include btn-white-stroke;
    }
  }
}
