/*============================================================================*\
  Content image box formula
\*============================================================================*/

$add-class: true !default;

/* Basic
\*==========================================================================*/

@if $add-class {
  .content-image-box-formula__bg {
    top: 16rem;
    height: calc(100% - 12rem);

    @screen xs {
      top: 42rem;
      height: calc(100% - 38rem);
    }

    @screen s {
      height: auto;
    }
  }

  .content-image-box-formula__caption {
    font-size: 9.375rem;

    @screen xxs {
      font-size: fluidTypographie(150, 200);
    }

    @screen xl {
      font-size: 12.5rem;
    }
  }
}
