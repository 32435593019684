/*============================================================================*\
  Field select
\*============================================================================*/

$add-class: true !default;

/* Basic
\*==========================================================================*/

@mixin field-select {
  @apply type-body font-light;
  display: block;
  width: 100%;
  padding: 0.938em 1.5em;
  padding-right: 3.6875em;
  cursor: pointer;
  appearance: none;
  background-color: rgba(#111, 0.08);
  // https://yoksel.github.io/url-encoder/
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="11" height="7" viewBox="0 0 11 7"%3E%3Cpath stroke="%23905337" transform="rotate(135 5.499017238616943,1.0000125169754028) " d="m2.31774,-2.18126l6.36256,0l0,6.36256" stroke-linecap="round" fill-rule="evenodd" fill="none" stroke-width="2"/%3E%3C/svg%3E%0A');
  background-repeat: no-repeat;
  background-position: right 1.5em center;
  border: 1px solid transparent;
  outline: none;

  &:focus {
    border-bottom-color: #{theme('colors.brown.default')};
  }

  &::placeholder {
    color: rgba(#333, 0.5);
  }

  // Form group status
  .field-group.has-error & {
    @apply text-notice-red;
    // https://yoksel.github.io/url-encoder/
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="11" height="7" viewBox="0 0 11 7"%3E%3Cpath stroke="%23d0021b" transform="rotate(135 5.499017238616943,1.0000125169754028) " d="m2.31774,-2.18126l6.36256,0l0,6.36256" stroke-linecap="round" fill-rule="evenodd" fill="none" stroke-width="2"/%3E%3C/svg%3E%0A');
    border-bottom-color: #{theme('colors.notice-red')};
    outline: none;
  }

  &:disabled {
    background-image: none;
  }
}

@if $add-class {
  .field-select {
    @include field-select;
  }
}
