/*============================================================================*\
  Badge
\*============================================================================*/

$add-class: true !default;

/* Basic
\*==========================================================================*/

@mixin badge {
  @apply border border-solid border-gold bg-gold;

  @apply text-smaller text-black-dark leading-small font-semibold;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  border-radius: 50%;
}

@if $add-class {
  .badge {
    @include badge;
  }
}

/* Stroke
\*==========================================================================*/

@mixin badge-stroke {
  color: currentColor;
  background-color: transparent;
}

@if $add-class {
  .badge--stroke {
    @include badge-stroke;
  }
}
