/*==========================================================================*\
  Header search
\*==========================================================================*/

/* Base
\*==========================================================================*/

.search__wrapper {
  transform: translateY(-100%);
  transition: transform 0.5s;

  .is-search-open & {
    transform: translateY(0);
  }
}
