/* stylelint-disable */
.dropdown-item:focus,
.dropdown-item:hover {
  background: none;
}
// .color,
// .custom-checkbox input[type='checkbox'] + span.color {
//   @include box-shadow-light;
//   display: inline-block;
//   width: 1.25rem;
//   height: 1.25rem;
//   margin: 0.3125rem;
//   cursor: pointer;
//   background-size: contain;
//   border: 1px solid rgba(0, 0, 0, 0.05);
//   &.active,
//   &:hover {
//     border: 2px solid $gray-darker;
//   }
// }
.facet-label {
  &.active,
  &:hover {
    // .custom-checkbox span.color {
    //   border: 2px solid $gray-darker;
    // }
  }
}
// .btn-primary,
// .btn-secondary {
//   @include box-shadow-light;
//   padding: 0.5rem 1.25rem;
//   font-weight: 600;
//   text-transform: uppercase;
//   .material-icons {
//     margin-right: $small-space;
//   }
// }
// .btn-tertiary {
//   @extend .btn-secondary;
//   margin: 0.25rem 0;
//   padding: 0.25rem;
//   font-size: $font-size-sm;
//   font-weight: 400;
//   color: $gray;
//   text-transform: lowercase;
//   background-color: #f7f7f7;
//   box-shadow: 0.0625rem 0.0625rem 0.0625rem 0 rgba(0, 0, 0, 0.1);
//   .material-icons {
//     font-size: $base-font-size;
//   }
//   &:hover {
//     border: $input-btn-border-width solid rgba(35, 35, 35, 0.2);
//     box-shadow: 0.0625rem 0.0625rem 0.0625rem 0 rgba(0, 0, 0, 0.2);
//   }
// }
// .btn-unstyle {
//   padding: 0;
//   text-align: inherit;
//   background-color: transparent;
//   border: none;
//   &:focus {
//     outline: 0;
//     .expand-more {
//       color: $brand-primary;
//     }
//   }
// }
// .card {
//   @include box-shadow;
// }
// label,
// .label {
//   font-size: 0.875rem;
//   color: $gray-darker;
//   text-align: right;
// }
// small.label,
// small.value {
//   font-size: 0.8125rem;
// }
// .form-control-label {
//   padding-top: 0.625rem;
// }
// .form-control {
//   padding: 0.5rem 1rem;
//   color: $gray;
//   background: $gray-light;
//   border: $input-btn-border-width solid $input-border-color;
//   &:focus {
//     color: $gray-darker;
//     background-color: white;
//     outline: 0.1875rem solid $brand-primary;
//   }
// }
// .input-group {
//   &.focus {
//     outline: 0.1875rem solid $brand-primary;
//   }
//   // .form-control:focus {
//   //   outline: none;
//   // }
//   .input-group-btn {
//     height: 100%;
//     > .btn {
//       margin-left: 0;
//       padding: 0.625rem 1rem;
//       font-size: 0.6875rem;
//       font-weight: normal;
//       color: #ffffff;
//       text-transform: uppercase;
//       border: 0;
//       box-shadow: none;
//       &[data-action='show-password'] {
//         padding: 0.78rem 1rem;
//         background: $gray;
//       }
//     }
//   }
// }
// input,
// textarea {
//   &::-webkit-input-placeholder {
//     color: $gray;
//   }
//   &::-moz-placeholder {
//     color: $gray;
//   }
//   &:-ms-input-placeholder {
//     color: $gray;
//   }
//   &:-moz-placeholder {
//     color: $gray;
//   }
//   &:active {
//     &::-webkit-input-placeholder {
//       color: $gray-darker;
//     }
//     &::-moz-placeholder {
//       color: $gray-darker;
//     }
//     &:-ms-input-placeholder {
//       color: $gray-darker;
//     }
//     &:-moz-placeholder {
//       color: $gray-darker;
//     }
//   }
// }
// .form-control-select {
//   height: 2.625rem;
//   padding: 0 0.5rem;
//   -moz-appearance: none;
//   -webkit-appearance: none;
//   background: $gray-light
//     url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAQAAAD9CzEMAAAAPklEQVR4Ae3TwREAEBQD0V/6do4SXPZg7EsBhsQ8IEmSMOsiuEfg3gL3oXC7wK0bd1G4o8X9F4yIkyQfSrIByQBjp7QuND8AAAAASUVORK5CYII=')
//     no-repeat scroll right 0.5rem center / 1.25rem 1.25rem;
// }
// .form-control-valign {
//   padding-top: 0.5rem;
// }
// .form-control-comment {
//   padding-top: 0.5rem;
//   font-size: 0.875rem;
//   color: $gray;
// }
// .form-control-submit {
//   &.disabled {
//     color: white;
//     background: $brand-info;
//   }
// }
// .form-group {
//   &.has-error {
//     input,
//     select {
//       outline: 0.1875rem solid $brand-danger;
//     }
//     .help-block {
//       color: $brand-danger;
//     }
//   }
// }
// .group-span-filestyle {
//   // label {
//   //   margin: 0;
//   // }
//   .btn-default {
//     padding: 0.5rem 1rem;
//     font-size: 0.875rem;
//     color: white;
//     text-transform: uppercase;
//     background: $brand-primary;
//     border-radius: 0;
//   }
// }
// .bootstrap-touchspin {
//   @include box-shadow-light;
//   display: inline-block;
//   float: left;
//   width: auto;
//   input {
//     &:focus {
//       outline: none;
//     }
//     // &.form-control,
//     &.input-group {
//       float: left;
//       width: 3rem;
//       height: $input-height;
//       padding: 0.175rem 0.5rem;
//       color: $gray-darker;
//       background-color: white;
//       border: $input-btn-border-width solid $input-border-color;
//     }
//   }
//   .btn-touchspin {
//     height: $input-height / 2 + 0.0625rem;
//     background-color: white;
//     border: $input-btn-border-width solid $input-border-color;
//     &:hover {
//       background-color: $gray-light;
//     }
//     @extend .btn-default;
//   }
//   .input-group-btn-vertical {
//     float: left;
//     width: auto;
//     color: $gray-darker;
//     .bootstrap-touchspin-up {
//       border-radius: 0;
//     }
//     .bootstrap-touchspin-down {
//       border-radius: 0;
//     }
//     .touchspin-up {
//       &:after {
//         content: '\E5CE';
//       }
//     }
//     .touchspin-down {
//       &:after {
//         content: '\E5CF';
//       }
//     }
//     i {
//       top: 0.0625rem;
//       left: 0.1875rem;
//       font-size: 0.9375rem;
//     }
//   }
// }
// .custom-radio {
//   position: relative;
//   display: inline-block;
//   width: 20px;
//   height: 20px;
//   margin-right: 1.25rem;
//   vertical-align: middle;
//   cursor: pointer;
//   background: white;
//   border: $gray 2px solid;
//   border-radius: 50%;
//   input[type='radio'] {
//     cursor: pointer;
//     opacity: 0;
//   }
//   input[type='radio'] {
//     &:checked + span {
//       position: absolute;
//       top: 0.125rem;
//       left: 0.125rem;
//       display: block;
//       width: 12px;
//       height: 12px;
//       background-color: $brand-primary;
//       border-radius: 50%;
//     }
//     &:focus + span {
//       border-color: $gray;
//     }
//   }
// }
// .custom-checkbox {
//   position: relative;
//   input[type='checkbox'] {
//     position: absolute;
//     margin-top: 0.25rem;
//     cursor: pointer;
//     opacity: 0;
//     + span {
//       display: inline-block;
//       width: 0.9375rem;
//       height: 0.9375rem;
//       margin-right: 3px;
//       vertical-align: middle;
//       cursor: pointer;
//       border: 2px $gray-darker solid;
//       .checkbox-checked {
//         display: none;
//         margin: -0.25rem -0.125rem;
//         font-size: 1.1rem;
//         color: $gray-darker;
//       }
//     }
//     &:checked + span {
//       .checkbox-checked {
//         display: block;
//       }
//     }
//     &:focus + span {
//       border-color: $gray;
//     }
//   }
//   // label {
//   //   text-align: left;
//   // }
// }
// .text-muted {
//   font-size: 0.875rem;
// }
.done {
  display: inline-block;
  margin-right: 1.563rem;
  padding: 0 0.8125rem;
  color: $brand-success;
}
// .thumb-mask {
//   > .mask {
//     position: relative;
//     width: 3.438rem;
//     height: 3.438rem;
//     margin: $small-space 0;
//     overflow: hidden;
//     border: $gray-light 1px solid;
//     img {
//       width: 55px;
//       height: 55px;
//     }
//   }
// }
// .definition-list {
//   dl {
//     display: flex;
//     flex-wrap: wrap;
//     dt {
//       font-weight: normal;
//     }
//     dd,
//     dt {
//       flex: 0 0 45%;
//       margin: 0.125rem;
//       padding: 0.625rem;
//       background: $gray-light;
//       &:nth-of-type(even) {
//         background: $gray-lighter;
//       }
//     }
//   }
// }
// .help-block {
//   margin-top: $small-space;
// }
// .btn.disabled,
// .btn.disabled:hover {
//   background: $gray;
// }
.alert-warning {
  .material-icons {
    margin-right: $small-space;
    padding-top: $extra-small-space;
    font-size: 2rem;
    color: $warning;
  }
  .alert-text {
    padding-top: $small-space;
    font-size: 0.9375rem;
  }
  .alert-link {
    margin-left: $small-space;
    padding: $extra-small-space $medium-space;
    font-size: 0.8125rem;
    font-weight: 600;
    color: $btn-tertiary-color;
    border-width: 2px;
    border-radius: 2px;
  }
  ul li:last-child .alert-link {
    color: white;
  }
  // .warning-buttons {
  //   margin-top: $extra-small-space;
  // }
}
// .btn-warning {
//   @include transition(all 0.4s ease-in-out);
// }
// .btn-tertiary-outline {
//   @include transition(all 0.4s ease-in-out);
//   color: $btn-tertiary-color;
//   background-color: transparent;
//   background-image: none;
//   border: 0.15rem solid $btn-tertiary-color;
//   border-color: $btn-tertiary-color;
//   &:hover {
//     color: $btn-tertiary-hover;
//     border-color: $btn-tertiary-hover;
//   }
// }
.alert {
  font-size: 0.8125rem;
}
// .nav-item {
//   .nav-link,
//   .nav-separtor {
//     font-weight: bold;
//     color: $gray;
//     &.active {
//       color: $gray-darker;
//     }
//   }
// }
// .separator {
//   margin: 0;
//   border-color: rgba(0, 0, 0, 0.25);
// }

// modal-dialog-centered class added from bootstrap 4.0.0, adapted for bootstrap 4.0.0-alpha.5
// .modal-dialog-centered {
//   display: flex;
//   align-items: center;
//   min-height: calc(
//     100% - (30px * 2)
//   ); // 30px correspond to the default margin size from bootstrap 4.0.0-alpha.5 for modal-dialog class
// }
