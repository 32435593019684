/* stylelint-disable */
#custom-text {
  margin-bottom: 1.5rem;
  padding: 3.125rem 3.125rem;
  text-align: center;
  background: white;
  border-radius: 2px;
  h2 {
    font-size: 1.563rem;
    font-weight: 700;
    color: $gray-darker;
    text-transform: uppercase;
  }
  p {
    font-size: 1.1em;
    font-weight: 400;
    color: $gray-darker;
    .dark {
      font-weight: 400;
      color: $gray;
    }
  }
}
// .page-content.page-cms {
//   padding: 1.25rem;
//   text-align: justify;
//   background: white;
//   .cms-box {
//     img {
//       max-width: 100%;
//     }
//   }
// }
@include media-breakpoint-down(md) {
  #block-cmsinfo {
    padding: $medium-space $large-space;
  }
}
