/*============================================================================*\
  Slider products
\*============================================================================*/

$add-class: true !default;

/* Classes
\*==========================================================================*/

@if $add-class {
  .slider-products {
    @screen l {
      .product-miniature__cursor {
        opacity: 1;
      }

      &.is-leave {
        .product-miniature__cursor {
          opacity: 0;
        }
      }
    }
  }

  .slider-products__item {
    width: 91.4285714%;
    padding-right: 1.5rem;

    @screen s {
      width: 41.666666666666664%;
    }

    @screen m {
      width: 34.1824158%;
      padding-right: 2rem;
    }
  }

  .slider-products__list {
    outline: none;

    &:not(.is-flickity-ready) {
      .slider-products__item:not(:first-child) {
        z-index: -1;
        position: absolute;
        opacity: 0;
      }
    }

    &.is-flickity-ready {
      .flickity-viewport {
        overflow: visible;
      }
    }
  }

  .slider-products__progress {
    height: 2px;
  }
}
