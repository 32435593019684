/*============================================================================*\
  Field checkbox
\*============================================================================*/

$add-class: true !default;

/* Basic
\*==========================================================================*/

// Wrapper
@mixin field-checkbox {
  display: inline-flex;
  align-items: baseline;
  cursor: pointer;
  outline: none;
}

@if $add-class {
  .field-checkbox {
    @include field-checkbox;
  }
}

// Input
@mixin field-checkbox-input {
  position: absolute;
  left: -99999px;

  &:focus {
    ~ .field-checkbox__fake-input {
      &:after {
        box-shadow: 0 0 0 2px #{theme('colors.brown.default')};
      }
    }
  }

  &:checked {
    ~ .field-checkbox__fake-input {
      &:before {
        opacity: 1;
      }
    }
  }
}

@if $add-class {
  .field-checkbox__input {
    @include field-checkbox-input;
  }
}

// Fake Input
@mixin field-checkbox-fake-input {
  top: 0.1em;
  display: inline-block;
  width: 1em;
  height: 1em;
  margin-right: 1em;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    top: 3px;
    right: 3px;
    bottom: 3px;
    left: 3px;
    margin: auto;
    background-color: transparent;
    background-image: url('data:image/svg+xml,%3Csvg viewBox="0 0 9.791 7.374" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M 1.394 0.064 L 8.394 0.064 L 8.394 4.064" stroke-width="2" fill="none" fill-rule="evenodd" stroke-linecap="round" transform="matrix(-0.707107, 0.707107, -0.707107, -0.707107, 9.814049, 0.062888)" stroke="%23905337"/%3E%3C/svg%3E');
    background-repeat: no-repeat;
    background-position: center center;
    opacity: 0;
    transition: opacity 0.1s;
  }

  &::after {
    @apply border-2 border-brown border-opacity-50;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 1;
  }
}

@if $add-class {
  .field-checkbox__fake-input {
    @include field-checkbox-fake-input;
  }
}

// Label
@mixin field-checkbox-label {
  @apply font-light;
  display: flex;
  flex: 1;
  cursor: pointer;

  a {
    @apply text-brown;
    text-decoration: underline;
  }
}

@if $add-class {
  .field-checkbox__label {
    @include field-checkbox-label;
  }
}
