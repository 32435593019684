/*============================================================================*\
  Category
\*============================================================================*/

$add-class: true !default;

/* Basic
\*==========================================================================*/

@if $add-class {
  .category {
    padding-bottom: 113%;
  }

  .category__background {
    transform: scale3d(1, 1, 1);
    transition: transform 1.4s;
    transition-delay: 0s;

    @apply ease-out-cubic;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-image: linear-gradient(36deg, #ab8475 7%, rgba(0, 0, 0, 0) 100%);
      opacity: 0.35;
    }
  }

  .category__content {
    opacity: 1;
    transform: scale3d(1, 1, 1);
    transition: opacity 0.4s, transform 0.4s;
    transition-delay: 0.2s;

    @apply ease-out-cubic;
  }

  .category__decoration {
    filter: blur(2px);
    opacity: 0;
    transform: scale3d(1.05, 1.05, 1) skewY(-12deg);
    transition: opacity 0.4s, transform 0.4s, filter 0.4s;
    transition-delay: 0s;

    @apply ease-out-cubic;
  }

  .category__cursor {
    transform: scale3d(1, 1, 1);
    transition: opacity 0.4s, transform 0.4s;

    @apply ease-out-cubic;
  }

  .category__pointer {
    opacity: 0;
    transition: opacity 0.05s, transform 0.075s;

    @apply ease-out-cubic;
  }

  .category__link {
    transition: transform 0.6s;

    @apply ease-out-cubic;

    &:hover,
    &:focus {
      .category__background {
        transform: scale3d(1.05, 1.05, 1);
        transition: transform 1.4s;
        transition-delay: 0s;

        @apply ease-out-cubic;
      }

      .category__content {
        opacity: 0;
        transition: opacity 0.4s, transform 0.4s;
        transition-delay: 0s;

        @apply ease-out-cubic;
      }

      .category__decoration {
        filter: blur(0);
        opacity: 1;
        transform: scale3d(1.01, 1.01, 1.01) skewY(-12deg);
        transition: opacity 0.4s, transform 0.4s, filter 0.4s;
        transition-delay: 0.2s;

        @apply ease-out-cubic;
      }

      .category__pointer {
        opacity: 1;
        transition: opacity 0.05s 0.075s, transform 0.075s;
      }
    }
  }
}
