/* stylelint-disable */
.menu-footer__collapse.show,
.menu-footer__collapse:not(.show) {
  @screen s {
    display: flex !important;
  }
}

.button__collapse:not(.collapsed) .icon {
  transform: rotateX(180deg);
}

.language-selector__button:not(.collapsed),
.language-selector__list {
  @apply bg-gold-dark;
}

.language-selector__list.collapsing,
.language-selector__list.collapse {
  transition: none;
}

.block_newsletter {
  @include search-box;
  margin-bottom: 0.625rem;
  font-size: $font-size-sm;
  p {
    padding-top: $small-space;
  }
  form {
    .input-wrapper {
      overflow: hidden;
    }
    input[type='email'] {
      width: 100%;
      padding: 11px;
      &:focus {
        padding: 8px 8px 9px;
        border: 3px $brand-primary solid;
        outline: 0;
      }
    }
    input {
      height: 42px;
      box-shadow: none;
    }
  }
}
.block-contact {
  color: $gray;
  .block-contact-title {
    color: $gray-darker;
  }
}
.linklist {
  .blockcms-title a {
    color: $gray-darker;
  }
}
.account-list a {
  color: $gray;
  &:hover {
    color: $brand-primary;
  }
}
.blockcms-title,
.myaccount-title,
.myaccount-title a,
.block-contact-title {
  font-size: $base-font-size;
  font-weight: 700;
}
.block-social {
  text-align: right;
  ul {
    display: flex;
    @include media-breakpoint-down(sm) {
      flex-flow: wrap;
    }
    li {
      display: inline-block;
      width: 2.5rem;
      height: 2.5rem;
      margin: 0.125rem;
      cursor: pointer;
      background-color: $gray-light;
      background-repeat: no-repeat;

      @include media-breakpoint-down(sm) {
        width: 2rem;
        height: 2rem;
        margin-right: 1.3rem;
        margin-bottom: 1.3rem;
        background-size: contain;
      }

      &:hover {
        background-color: $brand-primary;
      }
      a {
        display: block;
        height: 100%;
        overflow: hidden;
        text-indent: 100%;
        white-space: nowrap;
        &:hover {
          color: transparent;
        }
      }
    }
  }
}
.facebook {
  // background-image: url(../img/facebook.svg);
  &::before {
    content: '';
    // background-image: url(../img/facebook-blue.svg);
  }
  &.icon-gray {
    // background-image: url(../img/facebook-gray.svg);
    &:hover {
      // background-image: url(../img/facebook-blue.svg);
    }
  }
}
.twitter {
  // background-image: url(../img/twitter.svg);
  &::before {
    content: '';
    // background-image: url(../img/twitter-blue.svg);
  }
  &.icon-gray {
    // background-image: url(../img/twitter-gray.svg);
    &:hover {
      // background-image: url(../img/twitter-blue.svg);
    }
  }
}
.rss {
  // background-image: url(../img/rss.svg);
}
.youtube {
  // background-image: url(../img/youtube.svg);
}
.googleplus {
  // background-image: url(../img/gplus.svg);
  &::before {
    content: '';
    // background-image: url(../img/gplus-blue.svg);
  }
  &.icon-gray {
    // background-image: url(../img/gplus-gray.svg);
    &:hover {
      // background-image: url(../img/gplus-blue.svg);
    }
  }
}
#block_myaccount_infos {
  .myaccount-title a {
    color: $gray-darker;
  }
}
.pinterest {
  // background-image: url(../img/pinterest.svg);
  &::before {
    content: '';
    // background-image: url(../img/pinterest-blue.svg);
  }
  &.icon-gray {
    // background-image: url(../img/pinterest-gray.svg);
    &:hover {
      // background-image: url(../img/pinterest-blue.svg);
    }
  }
}
.vimeo {
  // background-image: url(../img/vimeo.svg);
}
.instagram {
  // background-image: url(../img/instagram.svg);
}
.links {
  .collapse {
    display: inherit;
  }
}

@include media-breakpoint-down(sm) {
  .block_newsletter {
    padding-bottom: 0.625rem;
    border-bottom: 1px solid $gray-lighter;
  }
  .links {
    // .collapse {
    //   display: none;
    //   &.in {
    //     display: block;
    //   }
    // }
    .title {
      padding: 0.625rem;
      cursor: pointer;
      border-bottom: 1px solid $gray-lighter;
      .collapse-icons .remove {
        display: none;
      }
    }
    .title[aria-expanded='true'] .collapse-icons {
      .add {
        display: none;
      }
      .remove {
        display: block;
      }
    }
    .navbar-toggler {
      display: inline-block;
      padding: 0;
    }
  }
}
@include media-breakpoint-down(md) {
  .block-social {
    text-align: center;
  }
  .block-contact {
    padding-left: 1.5rem;
  }
}
