/*==========================================================================*\
  Global
\*==========================================================================*/

/* Base
\*==========================================================================*/

*,
*:before,
*:after {
  position: relative;
}

html {
  @apply text-black;
  -webkit-font-smoothing: antialiased;

  &.is-menu-open {
    overflow: hidden;
  }

  @screen l {
    &.is-menu-open {
      overflow: auto;
    }
  }
}

body {
  @apply type-body bg-sand;
}

img {
  max-width: 100%;
  height: auto;
}

input::placeholder {
  color: currentColor;
  opacity: 0.5;
}

/* Selection
\*==========================================================================*/

::-moz-selection {
  @apply text-sand bg-brown;
  text-shadow: none;
}

::selection {
  @apply text-sand bg-brown;
  text-shadow: none;
}

/* Container
\*==========================================================================*/

// Page container
.page-center {
  width: 100%;
  width: calc(100% - #{theme('spacing.mobile-container-margin')});
  max-width: 1440px;
  margin-right: auto;
  margin-left: auto;

  > .grid {
    padding-right: 0;
    padding-left: 0;
  }
}

// Main container
.main-center {
  width: 100%;
  width: calc(100% - #{theme('spacing.mobile-container-margin')});
  max-width: 1216px;
  margin-right: auto;
  margin-left: auto;

  > .grid {
    padding-right: 0;
    padding-left: 0;
  }
}
