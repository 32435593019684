/*==========================================================================*\
  Topbar
\*==========================================================================*/

/* Base
\*==========================================================================*/

.topbar {
  .wysiwyg {
    a {
      color: currentColor !important;
    }
  }

  .is-menu-open & {
    @apply text-white bg-brown;
  }

  .is-scroll &,
  .is-submenu-enter & {
    @apply text-white bg-brown;
  }

  @screen l {
    .is-menu-open & {
      @apply text-current bg-transparent;
    }

    .is-scroll &,
    .is-submenu-enter & {
      @apply text-white bg-brown;
    }
  }
}

.topbar__wrapper {
  &:before {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 1px;
    background-color: currentColor;
    opacity: 0.15;
  }

  @screen l {
    min-height: 2rem;
  }
}
