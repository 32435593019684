/*============================================================================*\
  Breadcrumb
\*============================================================================*/

$add-class: true !default;

/* Classes
\*==========================================================================*/

@if $add-class {
  .breadcrumb__item {
    &:not(:last-child) {
      &:after {
        @apply bg-white;
        content: '';
        top: 0.05em;
        display: inline-block;
        width: 4px;
        height: 4px;
        margin: 0 0.625em;
        border-radius: 50%;
        opacity: 0.1;
      }
    }
  }
}

/* Text black
\*==========================================================================*/

@if $add-class {
  .breadcrumb--text-black {
    .breadcrumb__item {
      &:not(:last-child) {
        &:after {
          @apply bg-black;
        }
      }
    }
  }
}
