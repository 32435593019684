/*============================================================================*\
  Link
\*============================================================================*/

$add-class: true !default;

/* Basic
\*==========================================================================*/

@mixin link {
  @apply font-medium;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: currentColor;
  text-align: center;
  text-decoration: none;
  outline: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }

  svg {
    &:first-child {
      margin-right: 1em;
    }

    &:last-child {
      margin-left: 1em;
    }
  }
}

@if $add-class {
  .link {
    @include link;
  }
}

/* Animation
\*==========================================================================*/

@mixin link-animation {
  @apply font-semibold;

  &:hover,
  &:focus {
    text-decoration: none;
  }

  /* stylelint-disable */
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 0;
    height: 2px;
    margin-bottom: -0.75rem;
    background-color: transparent;
    transition: width 0s ease, background-color 0.3s ease;
  }

  &:after {
    @apply bg-brown;
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    display: block;
    width: 0;
    height: 2px;
    margin-bottom: -0.75rem;
    transition: width 0.3s ease;
  }

  &:hover,
  &:focus,
  &.is-active {
    &:before {
      @apply bg-brown;
      width: 100%;
      transition: width 0.3s ease;
    }

    &:after {
      width: 100%;
      background-color: transparent;
      transition: all 0s ease;
    }
  }
  /* stylelint-enable */
}

@mixin link-animation-active {
  /* stylelint-disable */
  &:before {
    @apply bg-brown;
    width: 100%;
    transition: width 0.3s ease;
  }

  &:after {
    width: 100%;
    background-color: transparent;
    transition: all 0s ease;
  }
  /* stylelint-enable */
}

@if $add-class {
  .link--animation {
    @include link-animation;

    &:hover,
    &:focus,
    &.is-active {
      @include link-animation-active;
    }
  }
}

/* Animation disabled
\*==========================================================================*/

@mixin link-animation-disabled {
  @apply text-black opacity-50;

  &:after {
    background-color: transparent;
  }

  &:hover,
  &:focus {
    /* stylelint-disable */
    &:before {
      width: 0;
      background-color: transparent;
      transition: width 0s ease, background-color 0.3s ease;
    }

    &:after {
      @apply bg-brown;
      width: 0;
      transition: width 0.3s ease;
    }
    /* stylelint-enable */
  }
}

@if $add-class {
  .link--animation-disabled {
    @include link-animation-disabled;
  }
}

/* Animation underline current color
\*==========================================================================*/

@mixin link-animation-underline-current {
  &:after {
    background-color: currentColor;
  }

  &:hover,
  &:focus {
    &:before {
      background-color: currentColor;
    }
  }
}

@if $add-class {
  .link--animation-underline-current {
    @include link-animation-underline-current;
  }
}
