/*============================================================================*\
  Product
\*============================================================================*/

$add-class: true !default;

/* Classes
\*==========================================================================*/

@if $add-class {
  .product-miniature__thumbnail-img-hover {
    opacity: 0;
    transition: opacity 0.3s;
  }

  .product-miniature__thumbnail-link {
    &:hover,
    &:focus {
      .product-miniature__thumbnail-img-hover {
        opacity: 1;
      }
    }

    &:focus {
      .figure__img {
        transform: scale3d(1.1, 1.1, 1) !important;
      }
    }

    &:hover {
      .figure__img {
        transform: scale3d(0.97, 0.97, 1) !important;
        // animation-name: scale-down-to-disapear;
        // animation-duration: 1s;
        // animation-direction: alternate;
        // animation-fill-mode: forwards;
      }
    }

    .figure:not(.figure--no-lazyload) .figure__img {
      transition: opacity 0.4s, transform 0.4s;
    }
  }

  .product-miniature__cursor {
    opacity: 0;
    transition: opacity 0.05s, transform 0.075s;

    .is-enter & {
      opacity: 1;
      transition: opacity 0.05s 0.075s, transform 0.075s;
    }
  }
}

// @keyframes scale-down-to-disapear {
//   0% {
//     transform: scale3d(1, 1, 1);
//   }
//   30% {
//     opacity: 1;
//     transform: scale3d(0.97, 0.97, 1);
//   }
//   60% {
//     opacity: 0%;
//     transform: scale3d(0.97, 0.97, 1);
//   }
//   100% {
//     opacity: 0%;
//     transform: scale3d(0.97, 0.97, 1);
//   }
// }
