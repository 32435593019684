/*============================================================================*\
  Price style
\*============================================================================*/

$add-class: true !default;

/* Basic
\*==========================================================================*/

@mixin price-style {
  @apply text-small leading-small font-medium text-black-light;
}

@if $add-class {
  .price-style {
    @include price-style;
  }
}
