/*============================================================================*\
  Figure
\*============================================================================*/

$add-class: true !default;

/* Basic
\*==========================================================================*/

@if $add-class {
  .figure {
    &:not(.figure--no-lazyload) {
      .figure__img {
        opacity: 0;
        transform: scale3d(1.05, 1.05, 1);
        transform-origin: 50% 50%;
        transition: opacity 1.4s, transform 1.4s;

        &.loaded {
          opacity: 1;
          transform: scale3d(1, 1, 1);
        }
      }
    }
  }
}
