/*============================================================================*\
  Content image box
\*============================================================================*/

$add-class: true !default;

/* Basic
\*==========================================================================*/

@if $add-class {
  .content-image-box__bg {
    top: 16rem;
    height: calc(100% - 12rem);

    @screen xs {
      top: 42rem;
      height: calc(100% - 38rem);
    }

    @screen s {
      height: auto;
    }
  }
}
