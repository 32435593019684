/*============================================================================*\
  Hero slider
\*============================================================================*/

$add-class: true !default;

/* Classes
\*==========================================================================*/

@if $add-class {
  .hero-slider__list {
    outline: none;

    // .flickity-viewport {
    //   overflow: visible;
    // }

    // &:not(.is-flickity-ready) {
    //   .hero-slider__item:not(:first-child) {
    //     z-index: -1;
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     opacity: 0;
    //   }
    // }
  }

  .hero-slider__item {
    z-index: -1;
    opacity: 0;
    transition: z-index 0s 0.5s, opacity 0.5s 0s;

    &.is-active {
      z-index: auto;
      opacity: 1;
      transition: z-index 0s 0s, opacity 0.5s 0s;
    }

    &:not(:first-child) {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .hero-slider__progress {
    height: 4px;

    &:before {
      @apply bg-black;
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0.1;
    }
  }

  .hero-slider__progress-thumb {
    transform: scaleX(0);
    transform-origin: 0% 0%;
  }
}
