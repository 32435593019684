/*==========================================================================*\
  Menu desktop
\*==========================================================================*/

/* stylelint-disable */

$add-class: false;
@import '../elements/link';
$add-class: true;

/* Base
\*==========================================================================*/

.menu-desktop {
  .menu-nav__submenu {
    @apply text-black bg-sand;
    margin-top: -60px;
    padding-top: 44px;
    box-shadow: 0px 5px 5px 0 rgba(0, 0, 0, 0.05);
    transition: margin-top 0.3s, padding-top 0.3s;
    transform: translateY(-200%);

    // Is scroll bottom
    .is-scroll-bottom & {
      margin-top: -25px;
      padding-top: 25px;
    }
  }

  .menu-nav__list--primary {
    margin-right: -(2.125rem / 2);
    margin-left: -(2.125rem / 2);
  }

  .menu-nav__item--primary {
    padding-right: (2.125rem / 2);
    padding-left: (2.125rem / 2);
  }

  .menu-nav__link--primary {
    @include link-animation;

    &:focus {
      @include link-animation-active;
    }
  }

  .menu-nav__item--primary {
    &:hover {
      .menu-nav__link--primary {
        @include link-animation-active;
      }

      .menu-nav__submenu {
        transform: translateY(0);
      }
    }
  }

  .menu-nav__item--secondary {
    &:not(:last-child) {
      @apply mb-6;
    }
  }
}
