/*============================================================================*\
  cms
\*============================================================================*/

$add-class: true !default;

/* Basic
\*==========================================================================*/

@if $add-class {
  .content-images-planes__media-viewport {
    display: none;

    @media (min-width: 1216px) {
      display: block;
      margin-right: 0 !important;
      margin-left: 0 !important;
    }
  }
}
