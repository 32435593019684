/*============================================================================*\
  Media-outer
\*============================================================================*/

$add-class: true !default;

/* Classes
\*==========================================================================*/

@if $add-class {
  .media-outer__container {
    height: 90vh;

    @screen s {
      height: auto;
      padding-bottom: 56%;
    }
  }

  .media-outer__media-viewport {
    @media (min-width: 1216px) {
      width: calc(100vw - ((100vw - 1216px) / 2));
      max-width: calc(1440px - ((1440px - 1216px) / 2));
      margin-right: 0 !important;
      margin-left: 0 !important;
    }
  }

  .media-outer__img {
    opacity: 0;
    transform: scale3d(1.05, 1.05, 1);
    transform-origin: 50% 50%;
    will-change: opacity, transform;
    transition: opacity 1.4s, transform 1.4s;

    .is-lazy-loaded & {
      opacity: 1;
      transform: scale3d(1, 1, 1);
    }
  }

  .media-outer__video {
    width: 100%;
    max-width: none;
    height: 100%;
    max-height: none;
    opacity: 0;
    transform: scale3d(1.05, 1.05, 1);
    transform-origin: 50% 50%;
    will-change: opacity, transform;
    transition: opacity 1.4s, transform 1.4s;
    object-fit: cover;
    object-position: center center;

    .is-lazy-loaded & {
      opacity: 1;
      transform: scale3d(1, 1, 1);
    }
  }

  .media-outer__title {
    padding-top: 0.25em;

    @screen l {
      font-size: 12.5rem;
    }
    // left: 50%;
    // transform: translateX(-50%);
    // transform: translate3d(50vw, 0, 0);
    // animation: marquee 10s linear infinite;

    // @screen s {
    //   transform: translate3d(0, 0, 0);
    //   animation: none;
    // }
  }
}

@keyframes marquee {
  0% {
    opacity: 0;
  }

  1% {
    opacity: 1;
  }

  98% {
    opacity: 1;
  }

  99% {
    opacity: 0;
    transform: translate3d(-100vw, 0, 0);
  }

  100% {
    opacity: 0;
    transform: translate3d (50vw, 0, 0);
  }
}
