/*============================================================================*\
  Field radio
\*============================================================================*/

$add-class: true !default;

/* Basic
\*==========================================================================*/

// Wrapper
@mixin field-radio {
  display: inline-flex;
  align-items: baseline;
  cursor: pointer;
  outline: none;
}

@if $add-class {
  .field-radio {
    @include field-radio;
  }
}

// Input
@mixin field-radio-input {
  position: absolute;
  left: -99999px;

  &:focus {
    ~ .field-radio__fake-input {
      &:after {
        box-shadow: 0 0 0 2px #{theme('colors.brown.default')};
      }
    }
  }

  &:checked {
    ~ .field-radio__fake-input {
      &:before {
        opacity: 1;
      }
    }
  }
}

@if $add-class {
  .field-radio__input {
    @include field-radio-input;
  }
}

// Fake Input
@mixin field-radio-fake-input {
  top: 0.1em;
  display: inline-block;
  width: 1em;
  height: 1em;
  margin-right: 1em;
  cursor: pointer;
  border-radius: 50%;

  &::before {
    @apply bg-brown;
    content: '';
    position: absolute;
    top: 4px;
    right: 4px;
    bottom: 4px;
    left: 4px;
    margin: auto;
    border-radius: 50%;
    opacity: 0;
    transition: opacity 0.1s;
  }

  &::after {
    @apply border-2 border-brown border-opacity-50;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 50%;
    opacity: 1;
  }
}

@if $add-class {
  .field-radio__fake-input {
    @include field-radio-fake-input;
  }
}

// Label
@mixin field-radio-label {
  @apply font-light;
  display: flex;
  flex: 1;
  cursor: pointer;

  a {
    @apply text-brown;
    text-decoration: underline;
  }
}

@if $add-class {
  .field-radio__label {
    @include field-radio-label;
  }
}
