/* stylelint-disable */
// body#checkout {
//   color: $gray-darker;
//   #header .header-nav {
//     // @include box-shadow;
//     // max-height: none;
//     // margin-bottom: 0;
//     // padding: 0.9375rem 0;
//     // border: none;
//     // .logo {
//     //   width: auto;
//     //   max-height: 4.375rem;
//     // }
//     // #_desktop_contact_link {
//     //   #contact-link {
//     //     margin-top: 0;
//     //   }
//     // }
//   }
//   .custom-checkbox {
//     display: flex;
//     span {
//       flex: 0 0 15px;
//       margin-top: 0.1875rem;
//     }
//   }
//   a:hover {
//     color: #1a8196;
//   }
//   section#content {
//     margin-bottom: 1.563rem;
//   }
//   .container {
//     min-height: 100%;
//   }
//   section.checkout-step {
//     // @include box-shadow;
//     // padding: 0.9375rem;
//     // background-color: white;
//     // border-bottom: 1px rgba(0, 0, 0, 0.25) solid;
//     // &:last-child {
//     //   border: 0;
//     // }
//     // .step-title {
//     //   margin-bottom: 0;
//     //   text-transform: uppercase;
//     //   cursor: pointer;
//     // }
//     // .content {
//     //   padding: 0 2.313rem;
//     // }
//     // .step-edit {
//     //   font-weight: normal;
//     //   text-transform: lowercase;
//     //   .edit {
//     //     font-size: $base-font-size;
//     //   }
//     // }
//     .not-allowed {
//       cursor: not-allowed;
//       opacity: 0.5;
//     }
//     .content,
//     .done,
//     .step-edit {
//       display: none;
//     }
//     &.-unreachable {
//       .step-title {
//         cursor: not-allowed;
//         opacity: 0.5;
//       }
//     }
//     &.-current {
//       .step-title {
//         cursor: default;
//       }
//       .content {
//         display: block;
//       }
//     }
//     &.-current.-reachable.-complete {
//       .done,
//       .step-edit {
//         display: none;
//       }
//       .step-number {
//         display: inline-block;
//       }
//       .content {
//         display: block;
//       }
//     }
//     &.-reachable.-complete {
//       h1 {
//         .done {
//           display: inline-block;
//         }
//         .step-number {
//           display: none;
//         }
//         .step-edit {
//           display: block;
//           // float: right;
//           // margin-right: 0.125rem;
//           // color: $gray;
//           cursor: pointer;
//         }
//       }
//       .content {
//         display: none;
//       }
//     }
//     // small {
//     //   color: $gray;
//     // }
//     // .default-input {
//     //   min-width: 40%;
//     //   &[name='address1'],
//     //   &[name='address2'] {
//     //     min-width: 60%;
//     //   }
//     // }
//     // .radio-field {
//     //   margin-top: 1.875rem;
//     //   label {
//     //     display: inline;
//     //   }
//     // }
//     // .checkbox-field div {
//     //   margin-top: 3.75rem;
//     // }
//     // .checkbox-field + .checkbox-field div {
//     //   margin-top: 0;
//     // }
//     // .select-field div {
//     //   padding: 0.625rem 3.125rem;
//     //   background: $gray-lighter;
//     // }
//     // .form-footer {
//     //   text-align: center;
//     // }
//     // #conditions-to-approve {
//     //   padding-top: 1rem;
//     // }
//     // .payment-options {
//     //   label {
//     //     display: table-cell;
//     //   }
//     //   // .custom-radio {
//     //   //   margin-right: $medium-space;
//     //   // }
//     //   .payment-option {
//     //     margin-bottom: 0.5rem;
//     //   }
//     // }
//     // .step-number {
//     //   display: inline-block;
//     //   padding: 0.625rem;
//     // }
//     // .address-selector {
//     //   display: flex;
//     //   flex-wrap: wrap;
//     //   align-items: flex-start;
//     //   justify-content: space-between;
//     // }
//     // .address-item {
//     //   flex: 0 0 49%;
//     //   margin-bottom: $small-space;
//     //   background: $gray-lighter;
//     //   border: 3px solid transparent;
//     //   &.selected {
//     //     background: white;
//     //     border: $brand-primary 3px solid;
//     //   }
//     // }
//     // .address-alias {
//     //   display: inline-block;
//     //   margin-bottom: 0.625rem;
//     //   font-weight: 600;
//     // }
//     // .address {
//     //   margin-left: 1.563rem;
//     //   font-weight: normal;
//     // }
//     // .radio-block {
//     //   padding: 0.9375rem;
//     //   text-align: left;
//     //   cursor: pointer;
//     // }
//     // .custom-radio {
//     //   margin-right: 0;
//     //   input[type='radio'] {
//     //     width: 1.25rem;
//     //     height: 1.25rem;
//     //   }
//     // }
//   //   .delete-address,
//   //   .edit-address {
//   //     display: inline-block;
//   //     margin: 0 0.3125rem;
//   //     color: $gray;
//   //     .delete,
//   //     .edit {
//   //       font-size: $base-font-size;
//   //     }
//   //   }
//   //   hr {
//   //     margin: 0;
//   //   }
//   //   .address-footer {
//   //     padding: 0.625rem;
//   //     text-align: center;
//   //   }
//   //   #delivery-addresses,
//   //   #invoice-addresses {
//   //     margin-top: 1.25rem;
//   //   }
//   //   .add-address {
//   //     margin-top: 1.25rem;
//   //     a {
//   //       color: $gray-darker;
//   //       i {
//   //         font-size: $font-size-lg;
//   //       }
//   //     }
//   //   }
//   //   .delivery-option {
//   //     margin-bottom: 0.9375rem;
//   //     padding: 0.9375rem 0;
//   //     background: $gray-lighter;
//   //     label {
//   //       text-align: inherit;
//   //     }
//   //     img {
//   //       width: 3.125rem;
//   //     }
//   //   }
//   //   .carrier-delay,
//   //   .carrier-name {
//   //     display: inline-block;
//   //     text-align: left;
//   //     word-break: break-word;
//   //   }
//   //   #customer-form,
//   //   #delivery-address,
//   //   #invoice-address,
//   //   #login-form {
//   //     margin-top: 1.563rem;
//   //     margin-left: 0.3125rem;
//   //     .form-control-label {
//   //       text-align: left;
//   //     }
//   //     // .radio-inline {
//   //     //   padding: 0;
//   //     // }
//   //   }
//   //   .sign-in {
//   //     font-size: $font-size-sm;
//   //   }
//   //   .forgot-password {
//   //     margin-left: 14.38rem;
//   //   }
//   }
//   .additional-information {
//     margin-top: 1.25rem;
//     margin-left: 2.875rem;
//     font-size: $font-size-sm;
//   }
//   .condition-label {
//     margin-top: 0.625rem;
//     margin-left: 2.5rem;
//     label {
//       text-align: inherit;
//     }
//   }
//   .cancel-address {
//     display: block;
//     margin: 0.625rem;
//     color: $gray;
//     text-decoration: underline;
//   }
//   .modal-content {
//     padding: $medium-space;
//     background-color: $gray-light;
//   }
//   #cart-summary-product-list {
//     font-size: $font-size-sm;
//     img {
//       width: 3.125rem;
//       border: $gray-light 1px solid;
//     }
//     .media-body {
//       vertical-align: middle;
//     }
//   }
//   #order-summary-content {
//     padding-top: 0.9375rem;
//     h4.h4 {
//       margin-top: 0.625rem;
//       margin-bottom: 1.25rem;
//       color: $gray-darker;
//     }
//     h4.black {
//       color: #000000;
//     }
//     h4.addresshead {
//       margin-top: 0.1875rem;
//     }
//     .noshadow {
//       box-shadow: none;
//     }
//     #order-items {
//       border-right: 0;
//       h3.h3 {
//         margin-top: 1.25rem;
//         color: $gray-darker;
//       }
//       table {
//         tr {
//           &:first-child {
//             td {
//               border-top: 0;
//             }
//           }
//         }
//       }
//     }
//     .order-confirmation-table {
//       margin-bottom: 2rem;
//       padding: 1rem;
//       background-color: #fff;
//       border: 3px solid #e5e5e5;
//       border-radius: 0;
//     }
//     .summary-selected-carrier {
//       margin-bottom: 0.75rem;
//       padding: 1rem;
//       background-color: #fff;
//       border: 1px solid #e5e5e5;
//       border-radius: 0;
//       .logo-container {
//         text-align: center;
//         img {
//           width: 3.125rem;
//         }
//       }
//     }
//     .step-edit {
//       display: inline;
//       color: $gray;
//     }
//     .step-edit:hover {
//       cursor: pointer;
//     }
//     a {
//       .step-edit {
//         color: $gray;
//       }
//     }
//   }
//   #gift_message,
//   #delivery {
//     max-width: 100%;
//     border-color: $gray-darker;
//     textarea {
//       max-width: 100%;
//       margin-bottom: 10px;
//     }
//   }
//   #footer {
//     @include box-shadow;
//     padding: 0.9375rem;
//     color: $gray;
//     background: white;
//   }
// }
/**** ORDER CONFIRMATION *****/
// #order-details {
//   margin-top: 1.25rem;
//   padding-left: 1.3rem;
//   > .card-title {
//     margin-bottom: $large-space;
//   }
//   ul {
//     margin-bottom: $medium-space;
//     li {
//       margin-bottom: $small-space;
//     }
//   }
// }
// #order-items {
//   border-right: $gray-light 1px solid;
//   .h3 {
//     font-size: 1rem;
//     &._desktop-title {
//       @include media-breakpoint-down(md) {
//         display: none;
//       }
//     }
//   }
//   hr {
//     border-top-color: $gray-darker;
//   }
//   table {
//     width: 100%;
//     tr {
//       height: $large-space;
//       td {
//         &:last-child {
//           text-align: right;
//         }
//       }
//       td {
//         padding: 0.5rem;
//         font-size: 0.875rem;
//       }
//       &.total-value {
//         background-color: $gray-light;
//       }
//       &.taxes {
//         td {
//           text-align: left;
//           .value {
//             padding-top: 0;
//           }
//         }
//       }
//     }
//   }
//   .order-line {
//     margin-top: 1rem;
//   }
//   .image {
//     img {
//       width: 100%;
//       margin-bottom: 1rem;
//       border: 1px solid gray-lighter;
//     }
//   }
//   .details {
//     margin-bottom: 1rem;
//     .customizations {
//       margin-top: 0.625rem;
//     }
//   }
//   .qty {
//     margin-bottom: 1rem;
//   }
// }
// #order-confirmation {
//   #registration-form {
//     width: 50%;
//     margin: 0 auto 1rem;
//   }
// }
@include media-breakpoint-down(md) {
  // .done {
  //   margin-right: 0.625rem;
  //   padding: 0;
  // }
  // body#checkout section.checkout-step .address-item {
  //   flex-grow: 1;
  // }
  // body#checkout section.checkout-step .delivery-option-2 {
  //   flex-direction: column;
  // }
  // .delivery-option {
  //   display: flex;
  //   margin: auto;
  //   // .custom-radio {
  //   //   flex: 0 0 auto;
  //   // }
  // }
  // .condition-label {
  //   label[for='conditions_to_approve[terms-and-conditions]'] {
  //     text-align: left;
  //   }
  // }
  // #order-confirmation {
  //   #registration-form {
  //     width: 100%;
  //   }
  // }
}

@include media-breakpoint-down(sm) {
  // body#checkout section.checkout-step.-reachable.-complete h1 .step-edit {
  //   float: none;
  //   margin-top: 0.25rem;
  //   margin-left: $medium-space;
  // }
  // body#checkout {
  //   #header .header-nav {
  //     max-height: none;
  //     padding: 0;
  //   }
  //   section.checkout-step {
  //     .content {
  //       padding: 0.9375rem;
  //     }
  //   }
  //   .form-group {
  //     margin-bottom: 0.5rem;
  //   }
  // }
  // #order-items {
  //   margin-bottom: 2.5rem;
  //   border-right: 0;
  //   .card-title {
  //     margin-bottom: 1rem;
  //     padding-bottom: 1rem;
  //     border-bottom: 1px solid $gray-light;
  //   }
  //   hr {
  //     border-top-color: $gray-light;
  //   }
  // }
  // .bold {
  //   font-weight: bold;
  // }
  // #order-details {
  //   padding-left: 0.9375rem;
  //   .card-title {
  //     margin-bottom: 1rem;
  //     padding-bottom: 1rem;
  //     border-bottom: 1px solid $gray-light;
  //   }
  // }
}

@include media-breakpoint-down(xs) {
  // body#checkout {
  //   section.checkout-step {
  //     .content {
  //       padding: 0.9375rem 0;
  //     }
  //   }
  // }
  // #payment-confirmation {
  //   button {
  //     font-size: 0.875rem;
  //     &.btn {
  //       white-space: normal;
  //     }
  //   }
  // }
}

// .cart-empty {
//   .cart-summary {
//   }
// }
.js-payment-binary {
  display: none;
  .accept-cgv {
    display: none;
  }
  &.disabled {
    cursor: not-allowed;
    opacity: 0.6;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      pointer-events: none;
    }
    .accept-cgv {
      display: block;
    }
  }
}
