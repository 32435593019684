/* stylelint-disable */
.carousel {
  margin-bottom: 1.5rem;
  box-shadow: 1px 1px 7px 0 rgba(0, 0, 0, 0.15);
  .direction {
    z-index: auto;
  }
  .carousel-inner {
    height: 340px;
  }
  .carousel-item {
    height: 100%;
    img {
      width: 100%;
      max-width: 100%;
      height: auto;
    }
    .caption {
      position: absolute;
      bottom: 28px;
      left: 90px;
      max-width: 340px;
      color: white;
      .caption-description p {
        color: white;
      }
    }
    figure {
      margin: 0;
    }
  }
  .carousel-control {
    opacity: 1;
    .icon-next,
    .icon-prev {
      &::before {
        content: '';
      }
      i {
        font-size: 3.125rem;
        color: white;
      }
      &:hover {
        i {
          color: $brand-primary;
        }
      }
    }
    .icon-prev {
      left: 1rem;
    }
    .icon-next {
      right: 2rem;
    }
    &.left,
    &.right {
      background: none;
    }
  }
}

@include media-breakpoint-down(lg) {
  .carousel {
    .carousel-inner {
      height: auto;
    }
  }
}

@include media-breakpoint-down(md) {
  .carousel {
    .carousel-item {
      .caption {
        bottom: calc(50% - 2rem);
        left: 10%;
      }
      .caption {
        .display-1 {
          font-size: 2rem;
        }
      }
      .caption-description {
        display: none;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .carousel {
    .carousel-item {
      .caption {
        bottom: calc(50% - 1.5rem);
      }
      .caption {
        .display-1 {
          font-size: 1.5rem;
        }
      }
    }
    .carousel-control {
      .icon-prev,
      .icon-next {
        i {
          font-size: 2rem;
        }
      }
    }
  }
}
