/*============================================================================*\
  Shadow palm
\*============================================================================*/

$add-class: true !default;

/* Basic
\*==========================================================================*/

@if $add-class {
  .shadow-palm__video {
    filter: contrast(1.25);
    mix-blend-mode: multiply;
    opacity: 0;
    transition: opacity 1s;

    &.loaded {
      opacity: 0.125;
    }
  }

  .shadow-palm__img {
    display: none;
  }

  .shadow-palm__wrapper {
    filter: blur(1px);

    html:not(.is-support-backgroundblendmode) & {
      .shadow-palm__video {
        display: none;
      }

      .shadow-palm__img {
        display: block;
      }
    }

    @screen s {
      filter: blur(2px);
    }

    @screen l {
      filter: blur(3px);
    }
  }
}

/* Reverse
\*==========================================================================*/

.shadow-palm--reverse {
  .shadow-palm__video,
  .shadow-palm__img {
    transform: rotateY(180deg);
  }
}
