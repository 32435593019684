/*============================================================================*\
  Hero
\*============================================================================*/

$add-class: true !default;

/* Classes
\*==========================================================================*/

@if $add-class {
  .hero {
    height: 100vh;

    @screen s {
      height: 95vh;
    }
  }

  .hero__content {
    padding-bottom: 90px;
  }

  @screen m {
    .hero__content {
      padding-right: 320px;
      padding-bottom: 0;
    }
  }

  .hero__img {
    opacity: 0;
    transform: scale3d(1.05, 1.05, 1);
    transform-origin: 50% 50%;
    will-change: opacity, transform;
    transition: opacity 1.4s, transform 1.4s;

    @apply ease-out-cubic;

    .is-lazy-loaded & {
      opacity: 1;
      transform: scale3d(1, 1, 1);

      @apply ease-out-cubic;
    }
  }

  .hero__video {
    width: 100%;
    max-width: none;
    height: 100%;
    max-height: none;
    opacity: 0;
    transform: scale3d(1.05, 1.05, 1);
    transform-origin: 50% 50%;
    will-change: opacity, transform;
    transition: opacity 1.4s, transform 1.4s;
    object-fit: cover;
    object-position: center center;

    .is-lazy-loaded & {
      opacity: 1;
      transform: scale3d(1, 1, 1);
    }
  }

  .hero__overlay {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-image: linear-gradient(37deg, rgba(#111111, 0.5), rgba(#111111, 0) 67%),
        linear-gradient(to bottom, rgba(#111111, 0.75) 1%, rgba(#111111, 0) 52%);
    }
  }

  @screen m {
    .hero__overlay {
      &:before {
        background-image: linear-gradient(42deg, rgba(#111111, 0.5) 11%, rgba(#111111, 0) 54%),
          linear-gradient(to bottom, rgba(#111111, 0.75) 5%, rgba(#111111, 0) 45%);
      }
    }
  }
}

/* Hero small
\*==========================================================================*/

@if $add-class {
  .hero--small {
    height: 90vh;

    @media (min-width: 375px) {
      height: 75vh;
    }

    @screen s {
      height: 85vh;
    }

    @screen l {
      height: 95vh;
    }
  }
}
