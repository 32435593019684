/*============================================================================*\
  Field text
\*============================================================================*/

$add-class: true !default;

/* Basic
\*==========================================================================*/

@mixin field-text {
  @apply type-body font-light;
  display: block;
  width: 100%;
  padding: 0.938em 1.5em;
  background-color: rgba(#111, 0.08);
  border: 1px solid transparent;
  outline: none;

  &:focus {
    border-bottom-color: #{theme('colors.brown.default')};
  }

  &::placeholder {
    color: rgba(#333, 0.5);
  }

  // Form group status
  .field-group.has-error & {
    @apply text-notice-red;
    border-bottom-color: #{theme('colors.notice-red')};
    outline: none;
  }
}

@if $add-class {
  .field-text {
    @include field-text;
  }
}

/* Password show
\*==========================================================================*/

@if $add-class {
  .field-text__toggle {
    width: 4rem;
    font-size: 0;

    .icon--eye-hidden {
      opacity: 0;
    }

    .field-group.has-error & {
      @apply text-notice-red;
    }

    @screen xs {
      width: 5.25rem;
    }
  }

  .field-text--password[type='text'] {
    padding-right: 5.5rem;

    ~ .field-text__toggle {
      .icon--eye {
        opacity: 0;
      }

      .icon--eye-hidden {
        opacity: 1;
      }
    }
  }
}
