/*============================================================================*\
  Field label
\*============================================================================*/

$add-class: true !default;

/* Basic
\*==========================================================================*/

@mixin field-label {
  @apply type-body font-regular;
  display: block;
  cursor: pointer;

  a {
    @include link;

    @apply text-brown font-semibold;
    text-decoration: underline;
    transition: color 0.2s;

    &:hover,
    &:focus {
      @apply text-black;
    }
  }
}

@if $add-class {
  .field-label {
    @include field-label;
  }
}

/* Optional
\*==========================================================================*/

@mixin field-label-optional {
  @apply text-small leading-small text-grey-dark font-medium;
  cursor: auto;
}

@if $add-class {
  .field-label--optional {
    @include field-label-optional;
  }
}
