/* stylelint-disable */
@import '~bourbon/app/assets/stylesheets/bourbon';
@import 'partials/_variables';
@import 'partials/import';
// @import 'components/bootstrap-touchspin'; // '~bootstrap-touchspin/src/jquery.bootstrap-touchspin.css'
@import 'partials/_mixins';
@import 'partials/_fonts';
@import 'partials/_commons';
@import 'components/alert';
@import 'components/drop-down';
@import 'components/search-widget';
@import 'components/mainmenu';
@import 'components/checkout';
@import 'components/customer';
@import 'components/forgotten-password';
@import 'components/renew-password';
@import 'components/imageslider';
@import 'components/featuredproducts';
@import 'components/custom-text';
@import 'components/categories';
@import 'components/products';
@import 'components/cart';
@import 'components/block-reassurance';
@import 'components/quickview';
@import 'components/stores';
@import 'components/footer';
@import 'components/contact';
@import 'components/errors';
@import 'components/customization-modal';
@import 'components/sitemap';
/*** HEADER ***/
// #header {
//   color: $gray;
//   background: white;
//   .logo {
//     max-width: 100%;
//   }
//   a {
//     color: $gray-darker;
//     &:hover {
//       color: $brand-primary;
//       text-decoration: none;
//     }
//   }
//   .menu {
//     display: inline-block;
//     > ul {
//       > li {
//         display: inline-block;
//       }
//     }
//   }
//   .header-nav {
//     max-height: 50px;
//     margin-bottom: 30px;
//     border-bottom: $gray-light 2px solid;
//     #menu-icon {
//       margin: 0 1rem;
//       vertical-align: middle;
//       cursor: pointer;
//       .material-icons {
//         line-height: 50px;
//       }
//     }
//     .right-nav {
//       display: flex;
//       flex-wrap: nowrap;
//       justify-content: flex-end;
//     }
//     .currency-selector {
//       margin-top: 0.9375rem;
//       margin-left: 0.9375rem;
//       white-space: nowrap;
//     }
//     .user-info {
//       margin-top: 0.9375rem;
//       margin-left: 2.5rem;
//       text-align: right;
//       white-space: nowrap;
//       .account {
//         margin-left: $small-space;
//       }
//     }
//     .language-selector {
//       margin-top: 0.9375rem;
//       white-space: nowrap;
//     }
//     .cart-preview {
//       &.active {
//         background: $brand-primary;
//         a,
//         i {
//           color: white;
//         }
//       }
//       .shopping-cart {
//         color: $gray;
//         vertical-align: middle;
//       }
//       .body {
//         display: none;
//       }
//     }
//     .blockcart {
//       height: 3rem;
//       margin-left: 0.9375rem;
//       padding: 0.75rem;
//       text-align: center;
//       white-space: nowrap;
//       background: $gray-light;
//       a {
//         color: $gray;
//         &:hover {
//           color: $brand-primary;
//         }
//       }
//       &.active {
//         a:hover {
//           color: white;
//         }
//       }
//       .header {
//         margin-top: 0.125rem;
//       }
//     }
//     #_desktop_contact_link {
//       display: inline-block;
//       #contact-link {
//         margin-top: 0.9375rem;
//       }
//     }
//     .search-widget {
//       margin-top: 0.2rem;
//     }
//     .material-icons {
//       line-height: inherit;
//       &.expand-more {
//         margin-left: -0.375rem;
//       }
//     }
//   }
//   .header-top {
//     padding-bottom: 1.25rem;
//     > .container {
//       position: relative;
//     }
//     .menu {
//       margin-bottom: 0.375rem;
//       padding-left: 15px;
//     }
//     .position-static {
//       position: static;
//     }
//     a[data-depth='0'] {
//       color: $gray;
//       text-transform: uppercase;
//     }
//     .search-widget {
//       float: right;
//     }
//   }
//   .top-menu-link {
//     margin-left: $medium-space;
//   }
// }

// .popover {
//   font-family: inherit;
// }
/*** WRAPPER ***/
// #wrapper {
//   padding-top: 1.563rem;
//   background: $gray-light;
//   box-shadow: inset 0 2px 5px 0 rgba(0, 0, 0, 0.11);
//   .banner {
//     display: block;
//     margin-bottom: 1.5rem;
//     img {
//       box-shadow: 1px 1px 7px 0 rgba(0, 0, 0, 0.15);
//     }
//   }
//   .breadcrumb {
//     padding: 0;
//     background: transparent;
//     &[data-depth='1'] {
//       display: none;
//     }
//     ol {
//       margin-bottom: 0;
//       padding-left: 0;
//     }
//     li {
//       display: inline;
//       &::after {
//         content: '/';
//         margin: 0.3125rem;
//         color: $gray;
//       }
//       &:last-child {
//         content: '/';
//         margin: 0;
//         color: $gray;
//         &::after {
//           content: '';
//         }
//       }
//       a {
//         color: $gray-darker;
//       }
//     }
//   }
// }
/*** MAIN ***/
// #main {
//   .page-header {
//     margin-bottom: 1.563rem;
//   }
//   .page-content {
//     margin-bottom: 1.563rem;
//     h6 {
//       margin-bottom: 1.125rem;
//     }
//     #notifications {
//       margin-right: -15px;
//       margin-left: -15px;
//     }
//   }
//   .page-footer {
//     margin-bottom: 1.563rem;
//   }
// }
#notifications {
  ul {
    margin-bottom: 0;
  }
}
/*** FOOTER ***/
// #footer {
//   padding-top: 2.5rem;
// }

/*** Responsive part ***/
@include media-breakpoint-down(lg) {
  // #header {
  //   .header-nav {
  //     max-height: inherit;
  //     .search-widget {
  //       float: none;
  //       width: 15.63rem;
  //     }
  //   }
  // }
}
@include media-breakpoint-down(md) {
  // .container {
  //   max-width: 100%;
  // }
  // #header {
  //   .logo {
  //     width: auto;
  //   }
  //   .header-top {
  //     .search-widget {
  //       min-width: inherit;
  //     }
  //   }
  // }
  // #products .product-miniature,
  // .featured-products .product-miniature {
  //   margin: 0 auto;
  // }
  // .sub-menu {
  //   left: 0;
  //   min-width: 100%;
  // }
  // #blockcart-modal .product-image {
  //   display: block;
  //   width: 100%;
  //   max-width: 15.63rem;
  //   margin: 0 auto 0.9375rem;
  // }
  // #blockcart-modal .cart-content {
  //   padding-left: 0;
  // }
  // #blockcart-modal .product-name,
  // #product-availability {
  //   margin-top: $small-space;
  // }
  // #search_filters .facet .facet-label {
  //   text-align: left;
  // }
  // .block-category .category-cover {
  //   position: relative;
  //   text-align: center;
  // }
  // .block-category {
  //   padding-bottom: 0;
  // }
}
@include media-breakpoint-down(sm) {
  // #wrapper {
  //   box-shadow: none;
  // }
  // #checkout-cart-summary {
  //   float: none;
  //   width: 100%;
  //   margin-top: 1rem;
  // }
  // #header {
  //   background: $gray-light;
  //   .header-nav {
  //     margin-bottom: 0.625rem;
  //     color: $gray-darker;
  //     background: white;
  //     .top-logo {
  //       padding-top: 11px;
  //       a {
  //         img {
  //           max-width: 100%;
  //           max-height: 50px;
  //         }
  //       }
  //     }
  //     .right-nav {
  //       flex-direction: column;
  //     }
  //     .user-info {
  //       margin-left: 0;
  //       text-align: left;
  //       .logged {
  //         color: $brand-primary;
  //       }
  //     }
  //     .blockcart {
  //       margin-left: 0;
  //       background: inherit;
  //       &.active {
  //         margin-left: 0.5rem;
  //       }
  //       &.inactive {
  //         .cart-products-count {
  //           display: none;
  //         }
  //       }
  //     }
  //   }
  //   .header-top {
  //     padding-bottom: 0;
  //     background: $gray-light;
  //     a[data-depth='0'] {
  //       color: $gray-darker;
  //     }
  //     .search-widget {
  //       width: 100%;
  //     }
  //   }
  //   &.is-open {
  //     background-color: white;
  //     .header-top {
  //       background-color: white;
  //     }
  //   }
  // }
  // section.checkout-step {
  //   width: 100%;
  // }
  // #checkout-cart-summary {
  //   float: none;
  //   width: 100%;
  //   margin-top: 1rem;
  // }
  // #header {
  //   background: $gray-light;
  //   .header-nav {
  //     margin-bottom: 0.625rem;
  //     color: $gray-darker;
  //     background: white;
  //     .top-logo {
  //       padding-top: 11px;
  //       a {
  //         img {
  //           max-width: 100%;
  //           max-height: 50px;
  //         }
  //       }
  //     }
  //     .right-nav {
  //       flex-direction: column;
  //     }
  //     .user-info {
  //       margin-left: 0;
  //       text-align: left;
  //       .logged {
  //         color: $brand-primary;
  //       }
  //     }
  //     .blockcart {
  //       margin-left: 0;
  //       background: inherit;
  //       &.active {
  //         margin-left: 0.5rem;
  //       }
  //       &.inactive {
  //         .cart-products-count {
  //           display: none;
  //         }
  //       }
  //     }
  //   }
  //   .header-top {
  //     padding-bottom: 0;
  //     background: $gray-light;
  //     a[data-depth='0'] {
  //       color: $gray-darker;
  //     }
  //     .search-widget {
  //       width: 100%;
  //     }
  //   }
  //   &.is-open {
  //     background-color: white;
  //     .header-top {
  //       background-color: white;
  //     }
  //   }
  // }
  // section.checkout-step {
  //   width: 100%;
  // }
  // .default-input {
  //   min-width: 100%;
  // }
  // label {
  //   clear: both;
  // }
  // #products .product-miniature,
  // .featured-products .product-miniature {
  //   margin: 0 auto;
  // }
  // .block-contact {
  //   padding-left: 0.9375rem;
  //   border: none;
  // }
  // .menu,
  // .dropdown-item {
  //   padding-left: 0;
  // }
}

@include media-breakpoint-down(xs) {
  // #left-column,
  // #content-wrapper {
  //   padding-right: 0 !important;
  //   padding-left: 0 !important;
  // }
}

input[type='number'] {
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    margin: 0;
    -webkit-appearance: none;
  }
}
