/*============================================================================*\
  Notification
\*============================================================================*/

$add-class: true !default;

/* Basic
\*==========================================================================*/

@mixin notification {
  @apply p-4 text-small leading-small text-white font-light bg-brown z-notification;
}

@if $add-class {
  .notification {
    @include notification;
  }
}

/* Error
\*==========================================================================*/

@mixin notification-error {
  @apply bg-notice-red;
}

@if $add-class {
  .notification--error {
    @include notification-error;
  }
}

/* Success
\*==========================================================================*/

@mixin notification-success {
  @apply bg-notice-green;
}

@if $add-class {
  .notification--success {
    @include notification-success;
  }
}

/* Warning
\*==========================================================================*/

@mixin notification-warning {
  @apply bg-notice-orange;
}

@if $add-class {
  .notification--warning {
    @include notification-warning;
  }
}
